import React from "react";

const Content3 = ({ className, ...rest }) => {
  return (
    <>   
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row pt-0 pb-0 pt-lg-0 pb-lg-0 align-items-center justify-content-center position-static">
          {/* Right Image */}
          <div className="col-xl-12 col-lg-12 order-lg-2 order-1">
            {/* content-2 start */}
            <div className="l5-content-gallery-img" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column w-100">
                <h1 className="text-center mt-25 text-black">Crystal Palace</h1>             
                  <h2 className="text-center font-size-6">Click around and discover Crystal Palace.</h2> 
                  <p className="text-center">Artwork by Mercedes Leon for <a href="https://www.placeinprint.com/products/art-prints/mercedes-leon/walk-around-crystal-palace">Place in Print</a></p>                 
                  {/* single image */}
                  <div className="digma-image mt-10" data-imageid="08132629-EC56-49BC-8228-CB60982B86C5"><img className="w-100" alt="housebuy" src="https://digmacustomers.blob.core.windows.net/2505e4ab-0b83-4ccb-8ff9-720611285935/4457655e-9b19-40d9-aeec-9c53185d6aee.jpg" /></div>                  
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>
          {/* End Right Image */}
        </div>
      </div>
    </div>    
    </>
  );
};

export default Content3;
