import React from "react";
import PageWrapper from "../../components/PageWrapper";
import CrystalPalace from "../../sections/interactive-images/crystalPalace";
import Commit from "../../sections/common/Cta";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/meta-images.jpg'
const Demo = () => {
  return (  
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
          version:"images",
        }}>
        <SEO
          title="Digma Interactive Images - Crystal Palace"
          description="Artwork by Mercedes Leon for Place in Print"
          image={metaImg}
        /> 
        <CrystalPalace />
        <div className="mt-20" style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
      </PageWrapper>
    </>
  );
};
export default Demo;
